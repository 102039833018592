<template>
    <FormWrapper>
        <PageTitle title="Probation Update" slot="title" link="/helpContent/Probation Update" />
        <br />
        <ValidationObserver ref="validator" >
            <FormRow>
                <div class="col-sm-4" >
                    <FormSelect label="Staff" :items="staffList" item-name="NAME" item-value="id"  v-model="form.selectedStaff" rules="required" :onChange="onChange" />
                </div>
            </FormRow>
            <FormRow>
                <div class="col-sm-4">
                    <inputDate label="Effective Date" v-model="form.date" rules="required" />
                </div>
            </FormRow>
            <br>
        </ValidationObserver>
        <FormRow>
            <div class="col-sm-6" >
                <ButtonGroup>
                    <FormButton type="success" @click="sendData()">Save</FormButton>
                </ButtonGroup>
            </div>
        </FormRow>
        <Popup title="Success" type="success" :value="showMessage.isVisible"  @close="() => (showMessage.isVisible = false)" :closable="true">
            {{ showMessage.message }}
        </Popup>
    </FormWrapper>
</template> 

<script>
    import FormWrapper from 'Components/form/FormWrapper';
    import PageTitle from 'Components/layout/PageTitle';
    import loadingMixin from 'Mixins/loadingMixin';
    import InputDate from "Components/form/InputDate";
    import {getStaffOnProbation, getProbationDate, probationCompletionProcess} from '../api';
    import { ValidationObserver } from 'vee-validate';
    export default {
        name: "Add",
        mixins: [loadingMixin],
        components: {
            FormWrapper,
            PageTitle,
            InputDate,
            ValidationObserver
        },
        data() {
            return {
                showMessage:{
                    isVisible: false,
                    message: ''
                },
                form:{
                    selectedStaff:'',
                    date:''
                },
                display:'initial',
                staffList: []
            }
        },
        computed: {
          userData() {
              return this.$store.state.user.user
            }
        },
        watch: {
        },
        created() {
            this.getDataByFilter()
        },
        methods: {
            getDataByFilter() {
                getStaffOnProbation().then((res) => {
                    this.staffList = res.data;
                    this.form.selectedStaff = this.userData.user_id;
                });
            },
            sendData(){
                this.$refs.validator.validate().then(result=>{
                    if(result){
                        this.showMessage.isVisible=false;
                        const data={
                            staff_id: this.form.selectedStaff,
                            EffectiveDate: this.form.date
                        };
                        probationCompletionProcess(data).then((res)=>{
                            this.form = {};
                            this.showMessage.isVisible=true;
                            this.showMessage.message=res.data[0].message;
                        })
                    }
                })
            },
            onChange(val) {
                const data={
                    staff_id: val
                };
                getProbationDate(data).then((res) => {
                    this.form.date = res.data[0].probation_completion_date;
                });
            },
        }
    }
</script>